<template>
	<div>
		<div class="">Documentos</div>
		<v-divider></v-divider>
		<v-data-table
			:headers="headers"
			:items="documents"
			:items-per-page="-1"
			class="elevation-1 my-8"
			hide-default-footer
            dense
		>
			<!-- <template v-slot:[`item.user_id`]="{ item }">
			{{ getUserName(item.user_id) }}
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<v-icon v-if="!disabled" @click="deleteItem(item)"> mdi-delete </v-icon>
		</template> -->
		</v-data-table>
	</div>
</template>
<script>
export default {
	name: 'DocumentTable',
	data: () => ({
		headers: [
			{
				text: 'Item',
				value: 'item',
				sortable: false,
				align: 'left',
				class: 'primary--text',
			},
			{
				text: 'Modificado',
				value: 'date',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Añadido por:',
				value: 'user',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Comentarios',
				value: 'comment',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
		],
	}),
	props: {
		value: {
			type: Array,
			required: false,
		},
	},
	computed: {
		documents: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {},
};
</script>
