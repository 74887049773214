//import { mapActions, mapState } from 'vuex';
import DocumentTable from '@/components/contract/search/elements/DocumentTable.vue';

export default {
	name: 'DocumentKeywordPreview',
	props: ['result', 'filters'],
	data: () => ({
		documents: [
			{
				item: '4.0',
				date: '12/09/2022 14:22',
				user: 'Ernesto Quispe',
				comment: 'Actualización de precios..',
			},
		],
	}),
	computed: {
		result_selected: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		subResults() {
			const arr = [];
			let documents = this.result?.documents;
			//let versions = this.result?.contractVersions;
			documents = Array.isArray(documents) ? documents : [];
			//versions = Array.isArray(versions) ? versions : [];

			documents.forEach((document) => {
				if (document?.id === this.result?.idDocument) {
					let pages = document.contractDocumentPages;
					pages = Array.isArray(pages) ? pages : [];
					pages.forEach((item) => {
						const data = {
							...item,
							url: `${document.file}?#page=${item.page}`,
							document_type: document?.type || null,
						};
						arr.push(data);
					});
				}
			});

			// versions.forEach((version) => {
			// 	let pages = version.contractVersionPages;
			// 	pages = Array.isArray(pages) ? pages : [];
			// 	pages.forEach((item) => {
			// 		const data = {
			// 			...item,
			// 			url: `${version.file}?#page=${item.page}`,
			// 		};
			// 		arr.push(data);
			// 	});
			// });

			return arr;
		},
	},
	created() {},
	methods: {
		paintWord(text) {
			if (this.filters?.q) {
				const lowerText = text.toLowerCase();
				const lowerSearch = (this.filters?.q || '').toLowerCase();

				const indexOfFirst = lowerText.indexOf(lowerSearch);
				if (indexOfFirst >= 0) {
					let indexBeforePoint = lowerText.lastIndexOf('.', indexOfFirst);
					let indexAfterPoint = lowerText.indexOf('.', indexOfFirst);
					if (indexBeforePoint == -1) indexBeforePoint = indexOfFirst - 150;
					if (indexAfterPoint == -1) indexAfterPoint = indexOfFirst + 150;
					if (indexBeforePoint > -1) indexBeforePoint += 1;
					text = text.substring(indexBeforePoint, indexAfterPoint);
				}
				const re = new RegExp(`\\b${this.filters?.q}\\b`, 'gi');
				text = text.replace(
					re,
					`<span class="back-yellow">${this.filters?.q}</span>`
				);

				return text;
			} else {
				return text;
			}
		},

		getCountkeyword(item) {
			const pages = item?.contractPages;
			const count = Array.isArray(pages) ? pages.length : null;
			if (count === 1) {
				return '1 resultado';
			} else if (count > 1) {
				return `${count} resultados`;
			} else {
				return null;
			}
		},

		hasKeyword(item) {
			const pages = item?.contractPages;
			const count = Array.isArray(pages) ? pages.length : 0;
			return count > 0;
		},

		getColor(expr) {
			switch (expr) {
				case 'PROVIDER':
					return 'yellow accent-1';
				case 'CLIENT':
					return 'green accent-2';
				case 'Pre - Contrato':
					return 'red lighten-4';
				case 'Contrato':
					return 'purple lighten-4';
				case 'LETTER':
					return 'cyan lighten-4';
				case 'ADDENDUM':
					return 'light-blue lighten-4';
				case 'REPORT':
					return 'teal lighten-4';
				default:
					return 'grey';
			}
		},

		getLabel(expr) {
			const types = [
				{ value: 'LETTER', label: 'Carta' },
				{ value: 'ADDENDUM', label: 'Adenda ' },
				{ value: 'REPORT', label: 'Acta' },
			];
			const found = types.find((e) => e.value === expr);
			return found?.label || expr;
		},
	},
	destroyed() {},
	components: {
		DocumentTable,
	},
};
